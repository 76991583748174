import Body1 from 'primitives/Typography/BodyCopy/Body1';
import FilledButton from 'primitives/Buttons/FilledButton';
import H4 from 'primitives/Typography/Headings/H4';
import NavLink from 'primitives/Typography/Links/NavLink';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import { Theme } from 'styles/themes/default';

export const Header = styled(H4)(({ theme }) => ({
  width: '100%',
  marginTop: theme.dimensions.pageGutter,
  marginBottom: theme.dimensions.gutter,
  justifyContent: 'center',
}));

export const BodyText = styled(Body1)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.dimensions.pageGutter,
  marginBottom: theme.dimensions.pageGutter,
}));

export const Button = styled(FilledButton)(
  ({
    theme,
  }: ComponentProps<typeof FilledButton> | { theme: Theme; role: string }) => ({
    backgroundColor: theme!.colors.red.primary,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginBottom: theme!.dimensions.pageGutter,
  }),
);

export const HelpLink = styled(NavLink)({
  display: 'block',
  textAlign: 'center',
  textDecoration: 'underline',
});
