// TODO: Once web-static-s3 is proxied through fastly remove this and use updated web-config (update it if needed)

import colors from 'styles/colors';
import LoadingIcon from 'styles/icons/player/Controls/Play/LoadingIcon';
import PageBody, { ViewName } from 'views/PageBody';
import TopIcons from './primitives/TopIcons';
import Wrapper from './primitives/Wrapper';
import { alexaLinkWasSuccessful } from 'state/Profile/selectors';
import { BodyText, Button, HelpLink } from './primitives/Text';
import { getQueryParams } from 'state/Routing/selectors';
import { linkIOSToAlexa } from 'state/Profile/actions';
import { ThunkDispatch } from 'state/types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const AlexaLinkHelpLink =
  'https://help.iheart.com/hc/en-us/articles/7125315060493-Linking-iHeartRadio-with-Alexa-from-the-iHeartRadio-App';

export default function AlexaLinkIOS() {
  const dispatch = useDispatch() as ThunkDispatch;
  const { scope: _scope, state: _state, code } = useSelector(getQueryParams);
  const linkSuccessful = useSelector(alexaLinkWasSuccessful);

  useEffect(() => {
    dispatch(
      linkIOSToAlexa({
        code,
        url: window.location.href,
      }),
    ).catch(() => {});
  }, []);

  if (linkSuccessful === false) {
    return (
      <PageBody dataTest={ViewName.AlexaLinkIOS} noAds>
        <Wrapper>
          <TopIcons success={false} />
          <BodyText role="heading">Uh-oh. Sorry, We Ran Into an Issue</BodyText>
          <a href="ihr://alexa/applink">
            <Button role="button">Go Back to the iHeart App</Button>
          </a>
          <HelpLink role="button" to={AlexaLinkHelpLink}>
            I need help
          </HelpLink>
        </Wrapper>
      </PageBody>
    );
  } else if (linkSuccessful === true) {
    return (
      <PageBody dataTest={ViewName.AlexaLinkIOS} noAds>
        <Wrapper>
          <TopIcons success={linkSuccessful || false} />
          <a href="ihr://alexa/applink">
            <Button role="button">Go Back to the iHeart App</Button>
          </a>
          <HelpLink role="button" to={AlexaLinkHelpLink}>
            I need help
          </HelpLink>
        </Wrapper>
      </PageBody>
    );
  } else {
    return (
      <PageBody dataTest={ViewName.AlexaLinkIOS} noAds>
        <Wrapper>
          <div style={{ height: '40px', position: 'relative' }}>
            <LoadingIcon stroke={colors.red.primary} strokeWidth={1.5} />
          </div>
          <BodyText role="note">Loading...</BodyText>
        </Wrapper>
      </PageBody>
    );
  }
}
