import styled from '@emotion/styled';

const Wrapper = styled('div')(() => ({
  width: '100%',
  justifyContent: 'center',
  maxWidth: '37.5rem',
  marginBottom: '8rem',
  marginTop: '8rem',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export default Wrapper;
